import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
// import editor from "../../Assets/Projects/codeEditor.png";
// import chatify from "../../Assets/Projects/chatify.png";
// import bitsOfCode from "../../Assets/Projects/blog.png";
import plantIdentifier  from "../../Assets/Projects/plantIdentifier.webp";
import instadescription from "../../Assets/Projects/instadescription.png";
import multimind from "../../Assets/Projects/multimind.webp";
import humanorAi from "../../Assets/Projects/humanorAi.webp";
import caption  from "../../Assets/Projects/caption.webp";
import wallpapersCats  from "../../Assets/Projects/wallpapersCats.webp";
import logoQuiz  from "../../Assets/Projects/logoQuiz.webp";

import animationTinder  from "../../Assets/imgArt/video/animationTinder.mp4";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

        <Col md={4} className="project-card">
            <ProjectCard
              //  imgPath={leaf}
              imgPath={plantIdentifier}
              isBlog={false}
              title="Plant Identifier: AI Scanner"
              description='🌿 Plant ID: your personal botanist in your pocket 🌿 
              Have you ever wondered what kind of plant you see when walking around? Or maybe you wanted to know how to care for your newly purchased green friend? Green Expert is a revolutionary app that will change your approach to the world of plants!'
              // ghLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              playLink="https://play.google.com/store/apps/details?id=com.inu.plantsai"
              // demoLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
            />
          </Col>

        <Col md={4} className="project-card">
            <ProjectCard
              //  imgPath={leaf}
              imgPath={multimind}
              isBlog={false}
              title="Multimind: Chat AI Bot"
              description="The best chatbot app on the market. Chatbot Multimindbot is an app that allows you to chat with our AI-based chatbot assistant that can help you with various problems and queries.

              You can use Multimindbot for many purposes, such as 📆 planning your day, 💬 establishing friendly conversations, 🎨 creating creative content, 🌐 searching for information on the Internet and much more. Chatbot Assistant is easy to use and adapts to your preferences and needs."
              // ghLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              playLink="https://play.google.com/store/apps/details?id=com.night.multimindbot"
              // demoLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={humanorAi}
              isBlog={false}
              title="Human or Ai"
              description="The application allows users to compare photos taken by humans and artificial intelligence. Its goal is to see if the user can recognize which photo was taken by an artificial intelligence and which one was taken by a human. The application allows the user to develop the ability to recognize the differences between photos taken by humans and artificial intelligence."
              // ghLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              playLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              // demoLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              //  imgPath={leaf}
              // imgPath="https://raw.githubusercontent.com/JakubJakubiak/Tinder_Twitch/main/images/1.jpg"
              videoLink={animationTinder}
              isBlog={false}
              title="Tinder_Twitch"
              description="Copy of Tinder app with login through Twitch, I will make and design an animation in React native similar to that of Tinder and write the whole application including the backend"
              ghLink="https://github.com/JakubJakubiak/Tinder_Twitch"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={caption}
              // videoLink="https://video.wixstatic.com/video/5aaa4a_0202f1acd66840ac9110deaf47655a2b/720p/mp4/file.mp4"
              isBlog={false}
              title="Hashtag Generator Ai"
              description="Hashtag AI Generator - Your social media assistant based on artificial intelligence!
              Say hello to Soci AI - your trusted companion in the world of social media, harnessing the potential of artificial intelligence. In the thicket of social media, where words build powerful networks, a new force is now available - the power of artificial intelligence. Soci AI is a breakthrough in social media optimization, combining creativity with advanced artificial intelligence algorithms."
              // ghLink="https://play.google.com/store/apps/details?id=com.inu.hashtaggenerator"
              playLink="https://play.google.com/store/apps/details?id=com.inu.hashtaggenerator"
              // demoLink="https://but-umowa.netlify.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              //  imgPath={leaf}
              imgPath={instadescription}
              isBlog={false}
              title="insta description generator Ai"
              description="Creating social media descriptions and Hastags from a photo in conjunction with payments in Stripe. The application was written using React"
              // ghLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              // playLink="https://play.google.com/store/apps/details?id=com.night.multimindbot"
              demoLink="https://insta-description-generator-ai-web.vercel.app"
            />
          </Col>


  

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={wallpapersCats}
              // videoLink="https://video.wixstatic.com/video/5aaa4a_0202f1acd66840ac9110deaf47655a2b/720p/mp4/file.mp4"
              isBlog={false}
              title="Wallpapers Cats"
              description="Wallpapers Cats is a great app for your phone, which gives you access to a wide selection of adorable wallpapers with cats. If you are a lover of these magnificent animals, our app is sure to meet your expectations."
              // ghLink="https://play.google.com/store/apps/details?id=com.inu.hashtaggenerator"
              playLink="https://play.google.com/store/apps/details?id=com.night.wallpapersCats"
              // demoLink="https://but-umowa.netlify.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={logoQuiz}            
              isBlog={false}
              title="Logo Quiz"
              description="Logos are often used in various places such as websites, advertising materials, products and other places to help people recognize and remember a particular brand or organization. Some logos are more well-known and recognizable than others, see if you know them all."
              // ghLink="https://play.google.com/store/apps/details?id=com.inu.hashtaggenerator"
              playLink="https://play.google.com/store/apps/details?id=inu.night.qizelogo"
              // demoLink="https://but-umowa.netlify.app"
            />
          </Col>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath="https://cdn-icons-png.flaticon.com/512/1126/1126862.png?w=2000&t=st=1685225490~exp=1685226090~hmac=033e7c584f73d179af6e2f6457d593112d48196b15bebe1a1041ee00b9d914d1"
              isBlog={false}
              title="PDF Signature "
              description="Possibility to sign on touch screen, Conversion of information to PDF format, Storage of PDF files on remote server"
              // ghLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              // playLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              demoLink="https://but-umowa.netlify.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath="https://raw.githubusercontent.com/JakubJakubiak/TranscribVision/main/images/Screen.png"
              isBlog={false}
              title="TranscribVision"
              description="A program for generating subtitles for movies. The subtitles are colored according to the prdkościon of the person's speech. Written in React Native"
              ghLink="https://github.com/JakubJakubiak/TranscribVision"
              // playLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              // demoLink="https://github.com/JakubJakubiak/TranscribVision"
            />
          </Col>

          

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath="https://raw.githubusercontent.com/JakubJakubiak/Programing_Story/main/png/Reactnativeexpo.jpg"
              isBlog={false}
              title="Wiki Tinder"
              description="An application that allows users to discover fascinating facts, similar to Tinder, but using content from Wikipedia. It features a unique interface that allows users to browse and rate trivia."
              ghLink="https://github.com/JakubJakubiak/Wiki_Tinder"
              // playLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              // demoLink="https://github.com/JakubJakubiak/TranscribVision"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath="https://raw.githubusercontent.com/JakubJakubiak/Programing_Story/main/png/super_jump.jpg"
              isBlog={false}
              title="Super Jump"
              description="A game in Unity where you jump over obstacles in slow motion."
              ghLink="https://github.com/JakubJakubiak/Super_Jump"
              // playLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              // demoLink="https://github.com/JakubJakubiak/TranscribVision"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

import React, {  useRef , useEffect  } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { SiGoogleplay } from "react-icons/si";

function ProjectCards(props) {
  const videoRef = useRef(null);

  useEffect(() => {
    const startVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch(error => {
          console.log("The video failed to start automatically:", error);
        });
      }
    };

    const handlePlay = () => {
      console.log("Video started playing.");
    };

    const handleLoop = () => {
      if (videoRef.current) {
        videoRef.current.play(); 
      }
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("play", handlePlay);
      videoRef.current.addEventListener("ended", handleLoop);
      startVideo(); 
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("play", handlePlay);
        videoRef.current.removeEventListener("ended", handleLoop);
      }
    };
  }, []);


  return (
    
    <Card className="project-card-view">
    {props.videoLink && (
      <video
        ref={videoRef}
        style={{
          width: "100%",
          height: "700px",
        }}
        controls={true}
      >
        <source src={props.videoLink} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    )}


      {props.imgPath && (
        <Card.Img variant="top" src={props.imgPath} alt={props.imgPath} />
      )}

      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>

        {props.ghLink && (
          <Button
            variant="primary"
            href={props.ghLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <BsGithub /> &nbsp; {"GitHub"}
          </Button>
        )}

        {props.playLink && (
          <Button
            variant="primary"
            href={props.playLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <SiGoogleplay /> &nbsp; {"Google Play"}
          </Button>
        )}

        {!props.isBlog && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite /> &nbsp; {"Demo"}
          </Button>
        )}


      </Card.Body>
    </Card>
  );
}

export default ProjectCards;

import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiAndroid,
} from "react-icons/di";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
} from "react-icons/si";

import { SiNetlify } from "react-icons/si";
import { GrDocker } from "react-icons/gr";
import { FaLinux } from "react-icons/fa";

  function Toolstack() {
    const [activeIcon, setActiveIcon] = useState("");

    const handleIconInteraction = (iconName) => {
      setActiveIcon(iconName === activeIcon ? "" : iconName);
    };;
  
    const renderIcon = (iconName, icon, text) => {
      return (
        <Col
          key={iconName}
          xs={4}
          md={2}
          className={`tech-icons ${activeIcon === iconName ? "active" : ""}`}
          onMouseLeave={() => handleIconInteraction("")}
          onClick={() => handleIconInteraction(iconName)}
        >
          {activeIcon === iconName ? <span>{text}</span> : icon}
          <h5>{text}</h5> 
        </Col>
      );
    };
    return (
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        {renderIcon("DiAndroid", <DiAndroid />, "Android")}
        {renderIcon("SiVisualstudiocode", <SiVisualstudiocode />, "VS Code")}
        {renderIcon("SiPostman", <SiPostman />, "Postman")}
        {renderIcon("SiSlack", <SiSlack />, "Slack")}
        {renderIcon("SiVercel", <SiVercel />, "Vercel")}
        {renderIcon("SiNetlify", <SiNetlify />, "Netlify")}
        {renderIcon("GrDocker", <GrDocker />, "Docker")}
        {renderIcon("FaLinux", <FaLinux />, "Linux")}
      </Row>
    );
  }

export default Toolstack;

import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
} from "react-icons/di";
import {
  SiFirebase,
  SiNextdotjs,
  SiFlutter,
  SiTypescript,
} from "react-icons/si";
import { RiTailwindCssFill } from "react-icons/ri";


function Techstack() {
  const [activeIcon, setActiveIcon] = useState("");

  const handleIconInteraction = (iconName) => {
    setActiveIcon(iconName === activeIcon ? "" : iconName);
  };;

  const renderIcon = (iconName, icon, text) => {
    return (
      <Col
        key={iconName}
        xs={4}
        md={2}
        className={`tech-icons ${activeIcon === iconName ? "active" : ""}`}
        // onMouseEnter={() => handleIconInteraction(iconName)}
        onMouseLeave={() => handleIconInteraction("")}
        onClick={() => handleIconInteraction(iconName)}
      >
        {activeIcon === iconName ? <span>{text}</span> : icon}
        <h5>{text}</h5> 
      </Col>
    );
  };

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {renderIcon("DiJavascript1", <DiJavascript1 />, "JavaScript")}
      {renderIcon("SiTypescript", <SiTypescript />, "TypeScript")}
      {renderIcon("DiPython", <DiPython />, "Python")}
      {renderIcon("DiNodejs", <DiNodejs />, "Node.js")}
      {renderIcon("DiReact", <DiReact />, "React")}
      {renderIcon("SiFlutter", <SiFlutter />, "Flutter")}
      {renderIcon("RiTailwindCssFill", <RiTailwindCssFill />, "TailwindCss")}
      {renderIcon("DiMongodb", <DiMongodb />, "MongoDB")}
      {renderIcon("SiNextdotjs", <SiNextdotjs />, "Next.js")}
      {renderIcon("DiGit", <DiGit />, "Git")}
      {renderIcon("SiFirebase", <SiFirebase />, "Firebase")}
    </Row>
  );
}

export default Techstack;

import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Jakub</span>
            from <span className="purple"> Poland.</span>
            <br /> For several years I have been passionately devoted to
            programming, which resulted in the creation of a
            variety of websites and applications that I have successfully made
            available on Google Play.
            <br />
            This independent work, carried out outside of formal employment, has
            allowed me to accumulate a solid base of practical knowledge.
            <br />
            <br />
          </p>

          {/* <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">JakubJakubiak</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import humanorAi from "../../Assets/Projects/humanorAi.webp";
// import wallpapersCats  from "../../Assets/Projects/wallpapersCats.webp";
import insaGui  from "../../Assets/imgArt/insaGui.webp";

import videoAnimationCaffe  from "../../Assets/imgArt/video/cafeAnimation.mp4";
import animationTinder  from "../../Assets/imgArt/video/animationTinder.mp4";




function Gui() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent Works<strong className="purple"> Gui</strong>
        </h1>
        <p style={{ color: "white" }}>
        Gui designs for mobile apps and more
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

  

        <Col md={4} className="project-card">
            <ProjectCard
              //  imgPath={leaf}
              // imgPath={gifowa}
              videoLink={videoAnimationCaffe}
              isBlog={false}
              title="Interface Application"
              description="Animation displaying a selected photo from the gallery "
              // ghLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              playLink="https://play.google.com/store/apps/details?id=com.inu.hashtaggenerator"
              // demoLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
            />
          </Col>

          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={insaGui}
              isBlog={false}
              title="Interface Application"
              description="Arrangement of elements on the keran in a readable way. "
              // ghLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              playLink="https://play.google.com/store/apps/details?id=com.inu.hashtaggenerator"
              // demoLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              //  imgPath={leaf}
              // imgPath={gifowa}
              videoLink={animationTinder}
              isBlog={false}
              title="Animacja wzorowana"
              description="I will make and design an animation in React native similar to that of Tinder and write the whole application including the backend"
              ghLink="https://github.com/JakubJakubiak/Tinder_Twitch"
              // playLink="https://play.google.com/store/apps/details?id=com.inu.hashtaggenerator"
              // demoLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={humanorAi}
              isBlog={false}
              title="Interfejs do aplikacji"
              description="Mimalistic interfjs with many functions , such as opening music or giving the correct answer to a question."
              // ghLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              playLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
              // demoLink="https://play.google.com/store/apps/details?id=inu.night.humanAI"
            />
          </Col>



        </Row>
      </Container>
    </Container>
  );
}

export default Gui;
